<template>
  <div class="date-selector">
    <div class="dropdown">
      <div class="dropdown-toggle border-none" @click="handleClickDays()">
        {{ this.currentLastDays }}
      </div>

      <div
        class="dropdown-menu is-bottom fit-content"
        :class="{ active: isDayOpen }"
        @click="handleClickDays()"
      >
        <ul class="dropdown-content">
          <li
            class="dropdown-item small"
            v-for="range in rangeItems"
            :key="range.id"
            @click="selectDays(range)"
          >
            {{ range.label }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  name: "DaySelector",
  data() {
    return {
      currentLastDays: 0,
      isDayOpen: false,
      rangeItems: [
        { id: 1, days: 30, label: "最近 30 天" },
        { id: 2, days: 90, label: "最近 90 天" },
        { id: 3, days: 180, label: "最近 180 天"},
        { id: 4, days: 365, label: "最近 1 年"},
        { id: 5, days: 730, label: "最近 2 年"},
        { id: 6, days: 1825, label: "最近 5 年"},
      ],
    };
  },
  async mounted() {
    const { startDate } = this.$route.query;
    const today = dayjs();
    const startDateTime = dayjs(startDate);
    const days = today.diff(startDateTime, "day");

    this.rangeItems.map(item => {
      if ( days === item.days ) {
        this.currentLastDays = item.label;
      }
    });
  },
  methods: {
    handleClickDays() {
      this.isDayOpen = !this.isDayOpen;
    },
    selectDays(day) {
      if (day.days !== this.currentLastDays) {
        this.$emit("updateDays", day.days);
      }
    },
  },
  watch: {
    currentLastDays(newVal) {
      this.currentLastDays = newVal;
    },
  },
};
</script>
