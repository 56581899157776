<template>
  <table class="table">
    <thead>
      <tr>
        <th class="is-textleft">交易時間</th>
        <th class="is-textleft">交易明細</th>
        <th class="is-textright">點數</th>
        <th class="is-textright">點數餘額</th>
      </tr>
    </thead>
    <tbody>
      <template v-if="pointsTransactions.length === 0">
        <tr>
          <td colspan="14">
            <div class="notification is-center">
              <strong>目前尚無任何點數使用記錄</strong>
            </div>
          </td>
        </tr>
      </template>
      <tr
        v-for="pointsTransaction in pointsTransactions"
        :key="pointsTransaction.transactionId"
      >
        <td class="is-textleft">
          <time>{{ pointsTransaction.createdTime }}</time>
        </td>
        <td class="is-textleft">
          {{
            pointsTransaction.transactionType === 1
              ? "充電服務"
              : pointsTransaction.transactionType === 2
              ? "點數獲取"
              : "點數轉贈"
          }}
        </td>
        <td class="is-textright" v-if="pointsTransaction.credits > 0">
          - {{ pointsTransaction.credits.toLocaleString() }}
        </td>
        <td class="is-textright" v-else>
          + {{ pointsTransaction.debits.toLocaleString() }}
        </td>
        <td class="is-textright">
          {{ pointsTransaction.balance.toLocaleString() }}
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: "PointTransactionsTable",
  props: {
    pointsTransactions: {
      type: Array,
      require: true,
    },
  },
};
</script>
