<template>
  <section class="section-main py-1">
    <div
      class="section-main__title is-space-between"
      :class="[isLoading ? '' : 'is-lined']"
    >
      <h2>
        點數餘額：<span class="balance">{{ balance ? balance : "-" }}</span>
      </h2>
      <DaySelector v-if="!isLoading" @updateDays="switchDays" />
    </div>
    <div class="section-main__content" v-if="isLoading">
      <inline-svg
        :src="require('../../assets/icon/svg/loading.svg')"
        class="loading"
      />
    </div>
    <PointTransactionsTable v-else :points-transactions="pointsTransactions" />
  </section>
</template>
<script>
import PointTransactionsTable from "../UI/Table/PointTransactionsTable.vue";
import DaySelector from "../UI/DaySelector.vue";
import pointsAPI from "../../apis/points";
import dayjs from "dayjs";
export default {
  name: "pointsTransactions",
  data() {
    return {
      currentLastDays: 0,
      isLoading: true,
      pointsTransactions: [
        {
          transactionId: "",
          userId: "",
          transactionType: 1,
          sourceId: "",
          debits: 0,
          credits: 0,
          balance: 0,
          createdTime: "",
        },
      ],
      balance: "",
    };
  },
  components: { PointTransactionsTable, DaySelector },
  async mounted() {
    const { cardNo, phoneNumber, page, pageSize, startDate } =
      this.$route.query;
    this.fetchPointsTransactions({
      queryCardNo: cardNo,
      queryPhoneNumber: phoneNumber,
      queryPage: Number(page),
      queryPagesize: Number(pageSize),
      queryStartDate: startDate,
    });
  },
  beforeRouteUpdate(to, from, next) {
    const { cardNo, phoneNumber, page, pageSize, startDate } = to.query;
    this.fetchPointsTransactions({
      queryCardNo: cardNo,
      queryPhoneNumber: phoneNumber,
      queryPage: Number(page),
      queryPagesize: Number(pageSize),
      queryStartDate: startDate,
    });
    next();
  },
  methods: {
    async fetchPointsTransactions({
      queryPage,
      queryPagesize,
      queryCardNo,
      queryPhoneNumber,
      queryStartDate,
    }) {
      try {
        this.isLoading = true;

        let { data, status } = await pointsAPI.pointsTransactions.get({
          page: Number(queryPage),
          pagesize: Number(queryPagesize),
          cardno: queryCardNo,
          phonenumber: queryPhoneNumber,
          startdate: queryStartDate,
        });
        if (status !== 200) {
          throw new Error(status);
        }
        let { pointsTransactions } = data;
        const modifiedData = pointsTransactions
          .sort((a, b) => {
            return new Date(b.createdTime) - new Date(a.createdTime);
          })
          .map((point) => {
            return {
              ...point,
              createdTime: dayjs
                .utc(point.createdTime)
                .tz("Asia/Taipei")
                .local()
                .format("YYYY/MM/DD HH:mm:ss"),
            };
          });
        this.pointsTransactions = modifiedData;
        this.balance = modifiedData[0].balance.toLocaleString();
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error.message);
      }
    },
    switchDays(day) {
      this.currentLastDay = +day;
      const { cardNo, phoneNumber, page, pageSize } = this.$route.query;
      const startDate = this.getStartDate(day);
      this.$router.push({
        name: "PointTransactions",
        query: {
          cardNo: cardNo,
          phoneNumber: phoneNumber,
          page: Number(page),
          pageSize: Number(pageSize),
          startDate: startDate,
        },
      });
    },
    getStartDate(duration) {
      const startDate = dayjs().subtract(duration, "Day").format("YYYY-MM-DD");
      return startDate;
    },
  },
  watch: {
    pointsTransactions(newVal) {
      this.pointsTransactions = newVal;
    },
  },
};
</script>
